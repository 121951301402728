@import "../var.scss";

.key-input-container {
  width: 100%;
  overflow: auto;
  overflow-x: hidden;

  h2 {
    margin: 0;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
  }

  h4 {
    margin: 0;
    margin-top: 1rem;
    font-size: 0.9rem;
    font-weight: 500;
  }

  input {
    background: $input-bg;
    border: none;
    padding: 0.8rem 0.6rem;
    border-radius: 10px;
    margin: 0.5rem 0;
    font-weight: 500;
    font-size: 1rem;
    width: 100%;

    &::placeholder {
      color: $grey;
    }

    &:focus {
      outline: none;
    }
  }

  textarea {
    background: $input-bg;
    border: none;
    padding: 0.8rem 0.6rem;
    border-radius: 10px;
    margin-top: 0.5rem;
    font-weight: 500;
    font-size: 1rem;
    width: 100%;
    resize: none;

    &::placeholder {
      color: $grey;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .signature {
    width: 100%;
    height: 150px;
    background: $input-bg;
    border-radius: 10px;
    margin: 0.5rem 0;

    canvas {
      width: 100%;
      height: 100%;
    }

    svg {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 1rem;
      height: 1.5rem;
      width: 1.5rem;

      &.hidden {
        opacity: 0;
      }

      fill: $dark-secondary;
    }
  }

  .button-container {
    display: flex;
  }

  button {
    margin: auto;
    margin-top: 1rem;
    width: 50%;
  }
}

@media only screen and (max-width: $mobile) {
  .key-input-container {
    margin-bottom: 5rem;

    textarea {
      height: 120px;
    }

    .button-container {
      width: 100%;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 1.2rem;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 29.17%
      );

      button {
        width: 100%;
      }
    }
  }
}
