@import "../var.scss";

.loading-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loader {
    border: 6px solid $primary;
    border-top: 6px solid $eggshell;
    margin-bottom: 1rem;
    width: 3.6rem;
    height: 3.6rem;
    animation: spin 2s linear infinite;
    border-radius: 50%;
  }

  h2 {
    color: $grey;
    font-weight: 600;
    font-size: 1.6rem;
    text-align: center;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
