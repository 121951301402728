@import "../var.scss";

.public-list-container {
  flex: 1;
  background: white;

  .public-header {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    img {
      height: 80px;
      mask-image: url("../assets/key-mask.svg");
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
    }

    .header-text {
      margin-right: auto;
      margin-left: 1rem;

      h1 {
        margin: 0;
        font-weight: 700;
        color: $secondary;
        font-size: 2rem;
      }

      h3 {
        margin: 0;
        font-weight: 700;
        font-size: 1.3rem;
      }
    }
  }

  .key {
    width: 60%;
    margin: 0 auto 2rem auto;
  }
}
