@import "../var.scss";

#header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;

  .user-container {
    display: flex;
    flex-direction: row;

    img {
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
    }

    .user-info {
      margin-left: 1rem;

      h4 {
        margin: 0;
        font-weight: 600;
        font-size: 1.1rem;
      }

      p {
        margin: 0;
        color: $primary;
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }

  h3 {
    color: $secondary;
    text-transform: uppercase;
    margin: 0;
    margin-left: auto;
    font-weight: 600;
    font-size: 1.1rem;
  }
}
