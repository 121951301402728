@import "../var.scss";

.back-button {
  padding: 1rem;
  margin-right: auto;
  transform: rotate(180deg);
  height: 25px;
  width: 25px;
  box-sizing: content-box;
  z-index: 3;

  svg {
    height: 100%;
    width: 100%;
    path {
      stroke: black;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
