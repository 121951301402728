@import "../var.scss";

$max-text-width: 320px;

.hero-container {
  background: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;

  .logo-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      mask-image: url("../assets/key-mask.svg");
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: auto 100%;
      transform: scale(1.2);
      max-height: 240px;
    }
  }

  .hero-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 90%;
    margin: auto;
    align-items: flex-start;

    h1 {
      max-width: $max-text-width;
      color: $secondary;
      font-weight: 700;
      font-size: 2.2rem;
      margin-bottom: 0.5rem;
    }

    h2 {
      max-width: $max-text-width;
      font-weight: 700;
      font-size: 1.6rem;
      margin: 0;
    }

    p {
      max-width: $max-text-width;
      font-size: 1.1rem;
      font-weight: 500;
      margin-top: 0.5rem;
    }

    a {
      max-width: $max-text-width;
      color: $primary;
      text-decoration: none;

      &:visited {
        color: $primary;
      }

      &::after {
        content: url("../assets/arrow.svg");
        padding-left: 0.5rem;
      }
    }

    button {
      margin-top: 2.2rem;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .hero-container {
    flex-direction: column;
    .logo-container {
      flex: 3;
      align-items: flex-end;

      .logo {
        transform: none;
      }
    }

    .hero-text {
      flex: 4;
      button {
        position: absolute;
        bottom: 2rem;
        left: 0;
        right: 0;
        width: 100%;
      }
    }
  }
}
