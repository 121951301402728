@import "../var.scss";

.swipe-send-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .swipe-guide {
    display: none;
    margin: 1rem;
  }

  .key-info-container {
    text-align: center;
    overflow: visible;

    .key {
      padding: 2rem;
      box-shadow: $shadow;
      background: white;
      margin: auto;
      width: 200px;
      height: 200px;
      border-radius: 10px;
    }

    h1 {
      font-weight: 600;
      font-size: 1.6rem;
      margin-bottom: 0;
    }

    h2 {
      font-weight: 600;
      font-size: 1.1rem;
      color: black;
      margin: 0.5rem;

      span {
        color: $primary;
      }
    }

    p {
      font-weight: 500;
      font-size: 1.1rem;
      color: $dark-secondary;
    }
  }

  .send-button {
    margin: 2rem;
    width: 200px;
  }
}

@media only screen and (max-width: $mobile) {
  .swipe-send-container {
    .swipe-guide {
      display: block;
    }

    .send-button {
      display: none;
    }
  }
}
