@import "../var.scss";

.key-info-container {
  width: 100%;
  overflow-x: hidden;

  h2 {
    margin: 0;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
  }

  h4 {
    margin: 0;
    margin-top: 1rem;
    font-size: 0.9rem;
    font-weight: 500;
    color: $dark-secondary;
  }

  p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .signature {
    background: $eggshell;
    padding: 2rem;
    border-radius: 10px;
    max-width: 100%;
    margin: 1rem 0;
  }

  a {
    color: $opensea;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    align-items: center;

    svg {
      margin: 0.5rem;
      height: 20px;
      width: 20px;

      path {
        fill: $opensea;
      }
    }
  }
}
