@import "../var.scss";

button {
  background: $primary;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.8rem;
  transition: 150ms ease-in-out;
  border: 2px solid $primary;

  &:disabled {
    opacity: 0.5;
  }

  &:enabled {
    &:hover {
      cursor: pointer;
      background: white;
      color: $primary;
    }
  }
}

@media only screen and (max-width: $mobile) {
  button {
    font-size: 1.2rem;
    padding: 1rem;
  }
}
