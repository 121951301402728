@import "../var.scss";

.key-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  video {
    box-shadow: $shadow;
    width: 300px;
    height: 300px;
    max-width: 90vw;
    max-height: 90vw;
    border-radius: 10px;
    object-fit: contain;
  }

  img {
    box-shadow: $shadow;
    width: 200px;
    height: 200px;
    padding: 50px;
    border-radius: 10px;
  }

  p {
    color: $dark-secondary;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0;
    margin-bottom: 1.5rem;
    font-style: italic;

    a {
      color: $secondary;
      text-decoration: none;
      &:visited {
        color: $secondary;
        text-decoration: none;
      }
    }
  }

  h2 {
    font-size: 1.1rem;
    color: $dark-secondary;
    font-weight: 600;
    margin: 1.5rem;
    margin-bottom: 0.5rem;
  }
}
