@import "../var.scss";

.alert {
  background: white;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeIn 200ms;

  svg {
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;

    &.error {
      color: $error;
    }

    &.success {
      color: $success;
    }

    &.key {
      width: 160px;
      height: 160px;
      max-width: 80%;
      margin-bottom: 2rem;
    }
  }

  h1 {
    font-size: 1.6rem;
    font-weight: 600;
    font-color: black;
    text-align: center;
    margin: 0.5rem;
  }

  h2 {
    font-size: 1.1rem;
    font-weight: 600;
    color: $grey;
    text-align: center;
    width: 300px;
    max-width: 80%;
    margin: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
