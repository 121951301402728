@import "../var.scss";

.qr-scan-receiver {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;

  .back-button {
    position: absolute;
    top: 0;
    left: 0;
  }

  #qr-code {
    margin-bottom: 3rem;
  }
  h1 {
    font-size: 1.6rem;
    font-weight: 600;
    font-color: black;
    text-align: center;
    margin: 0.5rem;
  }

  h2 {
    font-size: 1.1rem;
    font-weight: 600;
    color: $grey;
    text-align: center;
    width: 300px;
    max-width: 80%;
    margin: 0;
  }
}
