@import "../var.scss";

.content-container {
  box-sizing: border-box;
  background: white;
  padding: 1.2rem;
  border-radius: 10px;
  width: 100%;
  max-width: $mobile;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;

  .content-header {
    font-size: 1.1rem;
    margin: 0;
    font-weight: 600;
    margin-bottom: 0.6rem;
  }
}

@media only screen and (max-width: $mobile) {
  .content-container {
    margin: auto;
    border-radius: 30px 30px 0 0;
    flex: 1;
  }
}
