@import "./var.scss";

* {
  font-family: $font;
  position: relative;
  box-sizing: border-box;
}

html {
  background: $eggshell;
  overflow-x: hidden;
  scroll-behaviour: smooth;
}

.app {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
