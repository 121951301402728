@import "../var.scss";

.txn-item {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;

  .txn-info {
    max-width: 50%;
    h4 {
      color: $primary;
      font-size: 0.7rem;
      font-weight: 600;
      margin: 0;
    }

    h3 {
      font-size: 0.9rem;
      font-weight: 600;
      margin: 0;
    }

    p {
      margin: 0;
      font-size: 0.7rem;
      font-weight: 500;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $grey;
    }
  }

  a {
    margin-left: auto;
    color: $secondary;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 400;

    &:visited {
      color: $secondary;
    }
  }
}

.list-loader {
  border: 4px solid $primary;
  border-top: 4px solid $eggshell;
  margin-bottom: 1rem;
  width: 2rem;
  height: 2rem;
  animation: spin 2s linear infinite;
  border-radius: 50%;
  margin: auto;
}

.no-items {
  color: $grey;
  text-align: center;
  font-weight: 400;
  margin: 2rem;
}
