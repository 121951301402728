@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap");

$eggshell: #f7f8f8;
$primary: #eeb9c9;
$secondary: #85e0e0;
$success: #85e0a9;
$error: #f76e6e;
$input-bg: #f1f4f4;
$dark-secondary: rgba(10, 41, 41, 0.6);
$grey: #c4c4c4;
$opensea: #828689;

$shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);

$font: "Poppins", Arial, sans-serif;

$mobile: 688px;
$tablet: 992px;
$medium: 1312px;
