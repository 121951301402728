@import "../var.scss";

.new-key-button {
  width: $mobile;
  margin: auto;
  margin-top: 0;
  margin-bottom: 1rem;
}

@media only screen and (max-width: $mobile) {
  .new-key-button {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: none;

    &::after {
      content: url("../assets/plus.svg");
      object-fit: contain;
      height: 1.4rem;
      width: 1.4rem;
      position: fixed;
      bottom: 1.5rem;
      right: 1.5rem;
      padding: 1.5rem;
      border-radius: 50%;
      background: $primary;
      box-shadow: $shadow;

      &:active {
        opacity: 0.5rem;
      }
    }

    &.scan {
      &::after {
        content: url("../assets/scan.svg");
        position: fixed;
        object-fit: cover;
        width: 1.6rem;
        height: 1.6rem;
        bottom: 1.5rem;
        right: 1.5rem;
        border-radius: 50%;
        background: $primary;
        box-shadow: $shadow;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
