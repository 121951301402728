@import "../var.scss";

.wallet-scan {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $eggshell;

  video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .qr-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .no-video {
      margin-bottom: 2rem;
    }

    .permissions-prompt {
      background: none;
      color: black;
      text-decoration: underline;
    }

    .qr-guide {
      margin: 1rem;
    }

    p {
      font-size: 1.1rem;
      font-weight: 500;
      margin: 1rem;
    }

    .manual-input {
      background: white;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: $shadow;
      margin: 1rem;
      width: 90%;
      max-width: 360px;
      display: flex;
      flex-direction: row;

      input {
        border: none;
        font-weight: 500;
        font-size: 1.1rem;
        padding: 1rem;
        flex: 1;
        width: 100%;

        &:focus {
          outline: 0;
        }
      }

      button {
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 1.2rem;
        border: none !important;
        margin: 0;

        &[disabled] {
          opacity: 0.5;
        }
      }
    }
  }
}
