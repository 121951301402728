@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-container{background:white;width:100vw;height:100vh;display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row}.hero-container .logo-container{-webkit-flex:1 1;flex:1 1;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.hero-container .logo-container .logo{-webkit-mask-image:url(/static/media/key-mask.bd72a541.svg);mask-image:url(/static/media/key-mask.bd72a541.svg);-webkit-mask-position:center;mask-position:center;-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat;-webkit-mask-size:auto 100%;mask-size:auto 100%;-webkit-transform:scale(1.2);transform:scale(1.2);max-height:240px}.hero-container .hero-text{-webkit-flex:1 1;flex:1 1;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;max-width:90%;margin:auto;-webkit-align-items:flex-start;align-items:flex-start}.hero-container .hero-text h1{max-width:320px;color:#85e0e0;font-weight:700;font-size:2.2rem;margin-bottom:0.5rem}.hero-container .hero-text h2{max-width:320px;font-weight:700;font-size:1.6rem;margin:0}.hero-container .hero-text p{max-width:320px;font-size:1.1rem;font-weight:500;margin-top:0.5rem}.hero-container .hero-text a{max-width:320px;color:#eeb9c9;text-decoration:none}.hero-container .hero-text a:visited{color:#eeb9c9}.hero-container .hero-text a::after{content:url(/static/media/arrow.3eb6eeb3.svg);padding-left:0.5rem}.hero-container .hero-text button{margin-top:2.2rem}@media only screen and (max-width: 688px){.hero-container{-webkit-flex-direction:column;flex-direction:column}.hero-container .logo-container{-webkit-flex:3 1;flex:3 1;-webkit-align-items:flex-end;align-items:flex-end}.hero-container .logo-container .logo{-webkit-transform:none;transform:none}.hero-container .hero-text{-webkit-flex:4 1;flex:4 1}.hero-container .hero-text button{position:absolute;bottom:2rem;left:0;right:0;width:100%}}

button{background:#eeb9c9;border-radius:10px;color:white;font-weight:500;font-size:1rem;padding:0.8rem;transition:150ms ease-in-out;border:2px solid #eeb9c9}button:disabled{opacity:0.5}button:enabled:hover{cursor:pointer;background:white;color:#eeb9c9}@media only screen and (max-width: 688px){button{font-size:1.2rem;padding:1rem}}

#header{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-align-items:center;align-items:center;padding:1rem}#header .user-container{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row}#header .user-container img{height:3rem;width:3rem;border-radius:50%}#header .user-container .user-info{margin-left:1rem}#header .user-container .user-info h4{margin:0;font-weight:600;font-size:1.1rem}#header .user-container .user-info p{margin:0;color:#eeb9c9;font-weight:500;font-size:1rem}#header h3{color:#85e0e0;text-transform:uppercase;margin:0;margin-left:auto;font-weight:600;font-size:1.1rem}

.content-container{box-sizing:border-box;background:white;padding:1.2rem;border-radius:10px;width:100%;max-width:688px;margin:1rem auto;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}.content-container .content-header{font-size:1.1rem;margin:0;font-weight:600;margin-bottom:0.6rem}@media only screen and (max-width: 688px){.content-container{margin:auto;border-radius:30px 30px 0 0;-webkit-flex:1 1;flex:1 1}}

.txn-item{padding:0.5rem 0;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.txn-item .txn-info{max-width:50%}.txn-item .txn-info h4{color:#eeb9c9;font-size:0.7rem;font-weight:600;margin:0}.txn-item .txn-info h3{font-size:0.9rem;font-weight:600;margin:0}.txn-item .txn-info p{margin:0;font-size:0.7rem;font-weight:500;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:#c4c4c4}.txn-item a{margin-left:auto;color:#85e0e0;text-decoration:none;font-size:1.1rem;font-weight:400}.txn-item a:visited{color:#85e0e0}.list-loader{border:4px solid #eeb9c9;border-top:4px solid #f7f8f8;margin-bottom:1rem;width:2rem;height:2rem;-webkit-animation:spin 2s linear infinite;animation:spin 2s linear infinite;border-radius:50%;margin:auto}.no-items{color:#c4c4c4;text-align:center;font-weight:400;margin:2rem}

.key-container{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}.key-container video{box-shadow:0px 8px 20px rgba(0,0,0,0.1);width:300px;height:300px;max-width:90vw;max-height:90vw;border-radius:10px;object-fit:contain}.key-container img{box-shadow:0px 8px 20px rgba(0,0,0,0.1);width:200px;height:200px;padding:50px;border-radius:10px}.key-container p{color:rgba(10,41,41,0.6);font-size:0.8rem;font-weight:600;margin:0;margin-bottom:1.5rem;font-style:italic}.key-container p a{color:#85e0e0;text-decoration:none}.key-container p a:visited{color:#85e0e0;text-decoration:none}.key-container h2{font-size:1.1rem;color:rgba(10,41,41,0.6);font-weight:600;margin:1.5rem;margin-bottom:0.5rem}

.back-button{padding:1rem;margin-right:auto;-webkit-transform:rotate(180deg);transform:rotate(180deg);height:25px;width:25px;box-sizing:content-box;z-index:3}.back-button svg{height:100%;width:100%}.back-button svg path{stroke:black}.back-button:hover{cursor:pointer}

.wallet-scan{position:fixed;width:100vw;height:100vh;background-color:#f7f8f8}.wallet-scan video{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100%;object-fit:cover}.wallet-scan .qr-overlay{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100%;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.wallet-scan .qr-overlay .no-video{margin-bottom:2rem}.wallet-scan .qr-overlay .permissions-prompt{background:none;color:black;text-decoration:underline}.wallet-scan .qr-overlay .qr-guide{margin:1rem}.wallet-scan .qr-overlay p{font-size:1.1rem;font-weight:500;margin:1rem}.wallet-scan .qr-overlay .manual-input{background:white;border-radius:10px;overflow:hidden;box-shadow:0px 8px 20px rgba(0,0,0,0.1);margin:1rem;width:90%;max-width:360px;display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row}.wallet-scan .qr-overlay .manual-input input{border:none;font-weight:500;font-size:1.1rem;padding:1rem;-webkit-flex:1 1;flex:1 1;width:100%}.wallet-scan .qr-overlay .manual-input input:focus{outline:0}.wallet-scan .qr-overlay .manual-input button{border-radius:0;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;padding:1rem 1.2rem;border:none !important;margin:0}.wallet-scan .qr-overlay .manual-input button[disabled]{opacity:0.5}

.swipe-send-container{width:100vw;height:100vh;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.swipe-send-container .swipe-guide{display:none;margin:1rem}.swipe-send-container .key-info-container{text-align:center;overflow:visible}.swipe-send-container .key-info-container .key{padding:2rem;box-shadow:0px 8px 20px rgba(0,0,0,0.1);background:white;margin:auto;width:200px;height:200px;border-radius:10px}.swipe-send-container .key-info-container h1{font-weight:600;font-size:1.6rem;margin-bottom:0}.swipe-send-container .key-info-container h2{font-weight:600;font-size:1.1rem;color:black;margin:0.5rem}.swipe-send-container .key-info-container h2 span{color:#eeb9c9}.swipe-send-container .key-info-container p{font-weight:500;font-size:1.1rem;color:rgba(10,41,41,0.6)}.swipe-send-container .send-button{margin:2rem;width:200px}@media only screen and (max-width: 688px){.swipe-send-container .swipe-guide{display:block}.swipe-send-container .send-button{display:none}}

.loading-container{position:fixed;top:0;right:0;left:0;bottom:0;width:100vw;height:100vh;background:white;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.loading-container .loader{border:6px solid #eeb9c9;border-top:6px solid #f7f8f8;margin-bottom:1rem;width:3.6rem;height:3.6rem;-webkit-animation:spin 2s linear infinite;animation:spin 2s linear infinite;border-radius:50%}.loading-container h2{color:#c4c4c4;font-weight:600;font-size:1.6rem;text-align:center}@-webkit-keyframes spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}

.alert{background:white;z-index:999;width:100vw;height:100vh;position:fixed;top:0;bottom:0;left:0;right:0;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;-webkit-animation:fadeIn 200ms;animation:fadeIn 200ms}.alert svg{width:80px;height:80px;margin-bottom:1rem}.alert svg.error{color:#f76e6e}.alert svg.success{color:#85e0a9}.alert svg.key{width:160px;height:160px;max-width:80%;margin-bottom:2rem}.alert h1{font-size:1.6rem;font-weight:600;font-color:black;text-align:center;margin:0.5rem}.alert h2{font-size:1.1rem;font-weight:600;color:#c4c4c4;text-align:center;width:300px;max-width:80%;margin:0}@-webkit-keyframes fadeIn{0%{opacity:0}100%{opacity:1}}@keyframes fadeIn{0%{opacity:0}100%{opacity:1}}

.qr-scan-receiver{width:100vw;height:100vh;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;background:white}.qr-scan-receiver .back-button{position:absolute;top:0;left:0}.qr-scan-receiver #qr-code{margin-bottom:3rem}.qr-scan-receiver h1{font-size:1.6rem;font-weight:600;font-color:black;text-align:center;margin:0.5rem}.qr-scan-receiver h2{font-size:1.1rem;font-weight:600;color:#c4c4c4;text-align:center;width:300px;max-width:80%;margin:0}

.new-key-button{width:688px;margin:auto;margin-top:0;margin-bottom:1rem}@media only screen and (max-width: 688px){.new-key-button{width:0;height:0;margin:0;padding:0;overflow:hidden;border:none}.new-key-button::after{content:url(/static/media/plus.862ad05c.svg);object-fit:contain;height:1.4rem;width:1.4rem;position:fixed;bottom:1.5rem;right:1.5rem;padding:1.5rem;border-radius:50%;background:#eeb9c9;box-shadow:0px 8px 20px rgba(0,0,0,0.1)}.new-key-button::after:active{opacity:0.5rem}.new-key-button.scan::after{content:url(/static/media/scan.c8484370.svg);position:fixed;object-fit:cover;width:1.6rem;height:1.6rem;bottom:1.5rem;right:1.5rem;border-radius:50%;background:#eeb9c9;box-shadow:0px 8px 20px rgba(0,0,0,0.1);display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}}

.public-list-container{-webkit-flex:1 1;flex:1 1;background:white}.public-list-container .public-header{padding:1rem;display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-align-items:center;align-items:center}.public-list-container .public-header:hover{cursor:pointer}.public-list-container .public-header img{height:80px;-webkit-mask-image:url(/static/media/key-mask.bd72a541.svg);mask-image:url(/static/media/key-mask.bd72a541.svg);-webkit-mask-position:center;mask-position:center;-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat;-webkit-mask-size:contain;mask-size:contain}.public-list-container .public-header .header-text{margin-right:auto;margin-left:1rem}.public-list-container .public-header .header-text h1{margin:0;font-weight:700;color:#85e0e0;font-size:2rem}.public-list-container .public-header .header-text h3{margin:0;font-weight:700;font-size:1.3rem}.public-list-container .key{width:60%;margin:0 auto 2rem auto}

.key-info-container{width:100%;overflow-x:hidden}.key-info-container h2{margin:0;margin-bottom:1.5rem;font-size:1.5rem;font-weight:600}.key-info-container h4{margin:0;margin-top:1rem;font-size:0.9rem;font-weight:500;color:rgba(10,41,41,0.6)}.key-info-container p{margin:0;font-size:1.1rem;font-weight:500}.key-info-container .signature{background:#f7f8f8;padding:2rem;border-radius:10px;max-width:100%;margin:1rem 0}.key-info-container a{color:#828689;text-decoration:none;font-weight:500;font-size:1rem;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.key-info-container a svg{margin:0.5rem;height:20px;width:20px}.key-info-container a svg path{fill:#828689}

.key-input-container{width:100%;overflow:auto;overflow-x:hidden}.key-input-container h2{margin:0;margin-bottom:1.5rem;font-size:1.5rem;font-weight:600}.key-input-container h4{margin:0;margin-top:1rem;font-size:0.9rem;font-weight:500}.key-input-container input{background:#f1f4f4;border:none;padding:0.8rem 0.6rem;border-radius:10px;margin:0.5rem 0;font-weight:500;font-size:1rem;width:100%}.key-input-container input::-webkit-input-placeholder{color:#c4c4c4}.key-input-container input:-ms-input-placeholder{color:#c4c4c4}.key-input-container input::placeholder{color:#c4c4c4}.key-input-container input:focus{outline:none}.key-input-container textarea{background:#f1f4f4;border:none;padding:0.8rem 0.6rem;border-radius:10px;margin-top:0.5rem;font-weight:500;font-size:1rem;width:100%;resize:none}.key-input-container textarea::-webkit-input-placeholder{color:#c4c4c4}.key-input-container textarea:-ms-input-placeholder{color:#c4c4c4}.key-input-container textarea::placeholder{color:#c4c4c4}.key-input-container textarea:focus{outline:none}.key-input-container textarea::-webkit-scrollbar{display:none}.key-input-container .signature{width:100%;height:150px;background:#f1f4f4;border-radius:10px;margin:0.5rem 0}.key-input-container .signature canvas{width:100%;height:100%}.key-input-container .signature svg{position:absolute;bottom:0;right:0;margin:1rem;height:1.5rem;width:1.5rem;fill:rgba(10,41,41,0.6)}.key-input-container .signature svg.hidden{opacity:0}.key-input-container .button-container{display:-webkit-flex;display:flex}.key-input-container button{margin:auto;margin-top:1rem;width:50%}@media only screen and (max-width: 688px){.key-input-container{margin-bottom:5rem}.key-input-container textarea{height:120px}.key-input-container .button-container{width:100%;position:fixed;bottom:0;right:0;left:0;padding:1.2rem;background:linear-gradient(180deg, rgba(255,255,255,0) 0%, #fff 29.17%)}.key-input-container .button-container button{width:100%}}

*{font-family:"Poppins",Arial,sans-serif;position:relative;box-sizing:border-box}html{background:#f7f8f8;overflow-x:hidden;scroll-behaviour:smooth}.app{min-height:100vh;width:100vw;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}

